/* eslint-disable no-sequences */
import {put, takeEvery, call, select, all} from 'redux-saga/effects';
import {
  fetchRequest,
  fetchSuccess,
  fetchResultsDataSuccess,
  updateRequestConfigureGroupsModal,
  updateRequestConfigureGroupsModalSuccess,
  updateRequestConfigureGroupsModalFailure,
  updateMedianRatesAverageSites,
  saveAcrrualRequest,
  saveAcrrualSuccess,
  saveAcrrualFailure,
  fetchAccrualVersions,
  fetchAccrualVersionSuccess,
  fetchAccrualVersionFailure,
  fetchVersionRequest,
  fetchVersionDetailsSuccess,
  deleteAccrualVersion as deleteAccrualVersionAction,
  deleteAccrualVersionSuccess,
  deleteAccrualVersionFailure,
  createNewVersion,
  toogleLoading,
  renameAccrualVersion as renameAccrualVersionAction,
  renameAccrualVersionSuccess,
  renameAccrualVersionFailure,
} from '../../../slices/groupSequentialDesignSlice/accrualCalculator';
import {ENDPOINTS} from '../../../../../services/endpoint';
import {callApi} from '../../../../../services/api';
import {getSessionStorage} from '../../../../../common/utils/useSessionStorage';
import {filter} from 'lodash';
import {RootState} from '../../../../../app/store';
import {getActiveuser} from '../../../selectors/userDetailsSelector';
import {
  mergeSchemaWithFormValues,
  mapDefaultValues,
  extractFields,
  generateConfigureGroupsModalData,
  processOutput,
  hasCalculatedResults,
  updateCalculateDurationFormValues,
} from '../../../utils/accrualCalculatorUtils';
import {openBanner} from '../../../slices/BannerSlice';
import {logOutRequest} from '../../../../loginPage/slices/index';
import { CONSTANTS } from '../../../../../common/constants';

export default function* watchAccrualCalculatorSagas() {
  yield takeEvery(fetchRequest, getSessionFormsData);
  yield takeEvery(updateRequestConfigureGroupsModal, updateConfigureGroups);
  yield takeEvery(saveAcrrualRequest, postAccrualFormData);
  yield takeEvery(fetchAccrualVersions, getAccrualVersions);
  yield takeEvery(fetchVersionRequest, getVersionDetails);
  yield takeEvery(deleteAccrualVersionAction, deleteAccrualVersion);
  yield takeEvery(renameAccrualVersionAction, renameAccrualVersion);
}

let previousDropdownValues: Array<any> = [];

export function* getSessionFormsData({payload}: any): any {
  try {
    const {rid: resultId, projectId, accuralWorkflowAccepted} = payload;
    const activeTenantId: number = getSessionStorage('tenantid');
    const {tenantid, userid} = yield select(getActiveuser);
    const getSchemaURL = `${
      ENDPOINTS.COMMON_TRIAL_PARAMS
    }?objid=${2}&tenantid=${activeTenantId}&type=accrual`;
    const getFormDataURL = `${
      ENDPOINTS.GET_SAVED_ACCRUL_DATA
    }?tenantid=${activeTenantId}&resultid=${resultId}&onload=${true}`;
    const getAccrualVersions = `${ENDPOINTS.GET_SAVED_ACCRUL_DATA}?tenantid=${activeTenantId}&resultid=${resultId}&userid=${userid}&accuralworkflowaccepted=${accuralWorkflowAccepted}`;
    const getResultDataUrl = `${ENDPOINTS.GET_CUSTOM_OUTPUT_COMPARISION_RESULTS}?resultids=${resultId}&userid=${userid}&tenantid=${tenantid}&projectid=${projectId}`;
    // TODO: Check if we can get Interim subjects data in getAccrualData API call.
    let [{data: schemaData}, {data: formData}, {data: resultData}, {data: accrualVersions}] = yield all([
      call(callApi, getSchemaURL), // Fetch schema for the form
      call(callApi, getFormDataURL), // Fetch saved form values
      call(callApi, getResultDataUrl), // Fetch Results data to get Interim subjects/Total # Subjects.
      call(callApi, getAccrualVersions), // Get All versions for this result
    ]);
    if (schemaData?.length) {
      const defaultValues = mapDefaultValues(schemaData);
      let modifiedData = schemaData;
      let isGroupsConfigurationCompleted = false;
      let simulationDesignId = null;
      const isFormDataAvailiable =
        formData && formData[0] && Object.keys(formData[0]).length && Object.keys(formData[0].input).length;
      if (isFormDataAvailiable) {
        const {isGroupsConfigurationCompleted: status = false, ...restFormParams} = formData[0].input;
        simulationDesignId = formData[0].simulationDesignId;
        modifiedData = yield mergeSchemaWithFormValues(schemaData, restFormParams);
        isGroupsConfigurationCompleted = status;
      }
      const input = isFormDataAvailiable && formData[0].input;
      const version = isFormDataAvailiable && formData[0].version;
      const versionName = isFormDataAvailiable && formData[0].versionName;
      const status = isFormDataAvailiable && formData[0].status;
      const versions = accrualVersions || [];
      const fieldsInfo = yield extractFields(modifiedData);
      const rFormattedOutput = (isFormDataAvailiable && formData[0]?.rFormattedOutput) || {};
      const output = hasCalculatedResults(formData)
        ? processOutput(formData[0]?.output, rFormattedOutput)
        : [];
      yield put(
        fetchSuccess({
          ...fieldsInfo,
          schema: schemaData,
          defaultValues,
          isGroupsConfigurationCompleted,
          simulationDesignId,
          input,
          output,
          version,
          versionName,
          status,
          versions,
          rFormattedOutput,
        }),
      );
      yield put(fetchResultsDataSuccess({data: resultData, input, status}));
    }
  } catch (error) {
    console.warn(error);
  }
}

export function* getSavedSessionFormsData({payload}: any): any {
  try {
    const activeTenantId: number = getSessionStorage('tenantid');
    const url = `${ENDPOINTS.GET_SAVED_ACCRUL_DATA}?tenantid=${activeTenantId}&type=accrual`;
    const {data} = yield call(callApi, url);
    if (data.length) {
      const defaultValues = mapDefaultValues(data);
      const fieldsInfo = yield extractFields(data);
      yield put(fetchSuccess({...fieldsInfo, schema: data, defaultValues}));
    }
  } catch (error) {
    console.warn(error);
  }
}

type postAccrualdataType = {
  payload: {
    resultId: number;
    values: any;
    saveDraft: boolean;
    status: string;
    simulationDesginId: number;
    isGroupsConfigurationCompleted: boolean;
    isReCalculate: boolean;
    designType: string;
    cloneOf?: {
      simulationDesignId: number;
      action: string;
      cloneInProgress: boolean;
    };
    noOfSimulation?: number;
    isLoggingOut?: boolean;
    simulation?: boolean;
  };
};

export function* postAccrualFormData({payload}: postAccrualdataType): any {
  try {
    const activeTenantId: number = getSessionStorage('tenantid');
    const detail = getSessionStorage('accrualData');
    const {
      resultId,
      values,
      saveDraft,
      status,
      simulationDesginId,
      isGroupsConfigurationCompleted,
      isReCalculate = false,
      designType = '',
      cloneOf,
      noOfSimulation,
      simulation = false,
    } = payload;
    if ((detail?.endPoint === 'Time-to-Event' && detail?.usePiecewiseHazardRate === 'Yes') ||
    (detail?.endPoint !== 'Time-to-Event' && detail?.usePiecewiseHazardRate === 'No')) {
      delete values?.eventHazardRatesTTE;
    }
    const autosave = saveDraft ? 'true' : 'false';
    const statusParam = status === 'Completed' ? 'Completed' : 'Draft';
    const userId: number = getSessionStorage('userid');
    const options = yield select(
      (state: RootState) => state.groupSequentialDesign.accrualCalculator.dropDownValues,
    );
    const {isChangeinResults, updatedCalculateStudyDuration} = yield select(
      (state: RootState) => state.groupSequentialDesign.accrualCalculator,
    );
    const accrualFormValues =
      status === 'Completed' && isChangeinResults
        ? updateCalculateDurationFormValues(updatedCalculateStudyDuration, values)
        : values;
    const url = `${ENDPOINTS.SAVE_ACCRUAL_DATA}?tenantid=${activeTenantId}&resultid=${resultId}&autosave=${autosave}&status=${statusParam}&userid=${userId}`;
    const getAccrualVersions = `${ENDPOINTS.GET_SAVED_ACCRUL_DATA}?tenantid=${activeTenantId}&resultid=${resultId}`;
    const {data} = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        simulationDesignId: cloneOf?.cloneInProgress ? null : simulationDesginId,
        dropDownParams: isReCalculate ? [...options] : [],
        input: {
          ...accrualFormValues,
          isGroupsConfigurationCompleted: isGroupsConfigurationCompleted,
          meta: {
            designType,
            ...(cloneOf && {cloneOf: cloneOf?.simulationDesignId}),
          },
          noOfSimulation,
        },
      }),
    });

    if (payload.isLoggingOut) {
      yield put(logOutRequest());
    }
    const output = hasCalculatedResults(data)
      ? processOutput(data[0].output, data[0]?.rFormattedOutput || {})
      : [];
    yield put(saveAcrrualSuccess({data, output}));
    const predictedTimeAnalysisObject = data[0]?.rFormattedOutput?.interimTimes.find(
      (item: any) => item?._row === 'Predicted Time of Analysis',
    );
    const {'Interim  1': interimValue, 'Final': finalValue} = predictedTimeAnalysisObject;
    const [backendData] = data;
    const {error} = backendData;
    //Make Call to fetch versions once data is saved
    const {data: versionsData} = yield call(callApi, getAccrualVersions);
    if (versionsData) {
      yield put(fetchAccrualVersionSuccess({versions: versionsData}));
    }
    if (error) {
      yield put(
        openBanner({
          message: `The Accrual Calculation has been unsuccessful due to ${error}`,
          bannerType: 'error',
          autoClose: true,
        }),
      );
    } else if (simulation) {
      yield put(
        openBanner({
          message: 'The Patient Accrual simulation has been added successfully',
          bannerType: 'success',
          autoClose: true,
        }),
      );
    } else if (status === 'Draft') {
      yield put(
        openBanner({
          message: 'The Version has been saved successfully',
          bannerType: 'success',
          autoClose: true,
        }),
      );
    } else if (interimValue === null || finalValue === null) {
      yield put(
        openBanner({
          message: CONSTANTS.INFORMATIONAL_MESSAGES.ACCURAL_CALCULATE_WARNING_MESSAGE,
          bannerType: 'warning',
          autoClose: true,
        }),
      );
    } else {
      yield put(
        openBanner({
          message: 'The accruals has been calculated successfully',
          bannerType: 'success',
          autoClose: true,
        }),
      );
    }
    
  } catch (error: any) {
    console.warn(error);
    yield put(saveAcrrualFailure(error?.error));
    if(error?.error === undefined){
      yield put(
        openBanner({
          message: 'Your progress has been saved',
          bannerType: 'success',
          autoClose: true,
        }),
      );
    }else{
    yield put(
      openBanner({
        message: CONSTANTS.INFORMATIONAL_MESSAGES.ACCURAL_CALCULATE_ERROR_MESSAGE,
        bannerType: 'error',
        autoClose: true,
      }),
    );
  }
}
}

export function* updateConfigureGroups({payload}: any): any {
  try {
    // get the current state values here
    const state = yield select((state: RootState) => state.groupSequentialDesign.accrualCalculator);
    // now destruct calculateStudyDurationTTE property  from formInitialValues.calculateStudyDurationTTE
    const { calculateStudyDurationTTE: calculateStudyDurationTTEFromStore} = state.formInitialValues.calculateStudyDurationTTE;
    const {
      resultId,
      isFinalSave,
      configureGroupsValues,
      avgSites,
      medianRates,
      calculateStudyDuration,
      medianRatesTTE,
      calculateStudyDurationTTE,
      createNewModel: createNewModelFormValues,
      isReCalculate,
      designType,
      cloneOf,
      isLoggingOut,
    } = payload;
    console.log('calculateStudyDurationTTE from payload',calculateStudyDurationTTE);
    
    const simulationDesginId: number = yield select(
      (state: RootState) => state.groupSequentialDesign.accrualCalculator.simulationDesginId,
    );
    const tenantId: number = getSessionStorage('tenantid');
    const userId: number = getSessionStorage('userid');
    const {formValues, dropDownParams} = generateConfigureGroupsModalData(configureGroupsValues);
    let dropDownParamsUnique: any = [];
    if (dropDownParams.length) {
      const filterDropDownValues = filter(
        dropDownParams,
        (values: string) => !previousDropdownValues.includes(values),
      );
      dropDownParamsUnique = filterDropDownValues;
    }
    previousDropdownValues = dropDownParams;
    const url = `${ENDPOINTS.SAVE_ACCRUAL_DATA}?tenantid=${tenantId}&resultid=${resultId}&userid=${userId}&autosave=true&status=Draft`;
    const createNewModel = {
      ...createNewModelFormValues,
      ConfigureGroups: formValues,
    };
    const body = {
      input: {
        createNewModel,
        ...formatAccordianData({
          avgSites,
          medianRates,
          calculateStudyDuration,
          medianRatesTTE,
          calculateStudyDurationTTE: calculateStudyDurationTTEFromStore,
        }),
        isGroupsConfigurationCompleted: isFinalSave,
        meta: {designType, ...(cloneOf && {cloneOf: cloneOf?.simulationDesignId})},
      },
      simulationDesignId: cloneOf?.cloneInProgress ? null : simulationDesginId || '',
      dropDownParams: dropDownParamsUnique,
    };
    if (!isReCalculate) {
      const {data, message} = yield call(callApi, url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(body),
      });

      if (isLoggingOut) {
        yield put(logOutRequest());
      } else if (message === 'Successful') {
        let response = {};
        if (data.length) {
          const {
            input: {createNewModel, isGroupsConfigurationCompleted, meta},
            simulationDesignId,
            version,
          } = data[0];
          response = {
            createNewModel,
            smltn_dsgn_mdl_id: simulationDesignId,
            isGroupsConfigurationCompleted,
            dropDownParams: dropDownParamsUnique,
            createNewModelFormValues,
            version,
            meta,
          };
        } else {
          response = {
            createNewModel,
            isGroupsConfigurationCompleted: isFinalSave,
            dropDownParams: dropDownParamsUnique,
            createNewModelFormValues,
          };
        }
        yield put(updateRequestConfigureGroupsModalSuccess(response));
        yield put(
          updateMedianRatesAverageSites({
            isGroupsConfigurationCompleted: isFinalSave,
            avgSites,
            medianRates,
            calculateStudyDuration,
            medianRatesTTE,
            calculateStudyDurationTTE:calculateStudyDurationTTEFromStore,
          }),
        );
      }
    } else {
      if (isLoggingOut) {
        yield put(logOutRequest());
      }
      yield put(
        updateRequestConfigureGroupsModalSuccess({
          createNewModel,
          isGroupsConfigurationCompleted: isFinalSave,
          dropDownParams: dropDownParamsUnique,
          createNewModelFormValues,
        }),
      );
      yield put(
        updateMedianRatesAverageSites({
          isGroupsConfigurationCompleted: isFinalSave,
          avgSites,
          medianRates,
          calculateStudyDuration,
          medianRatesTTE,
          calculateStudyDurationTTE:calculateStudyDurationTTEFromStore,
        }),
      );
    }
  } catch (error) {
    yield put(updateRequestConfigureGroupsModalFailure(error));
    console.warn(error);
  }
}

export function* getAccrualVersions({payload}: any): any {
  try {
    const {resultId} = payload;
    const activeTenantId: number = getSessionStorage('tenantid');
    const getAccrualVersions = `${ENDPOINTS.GET_SAVED_ACCRUL_DATA}?tenantid=${activeTenantId}&resultid=${resultId}`;
    const {data} = yield call(callApi, getAccrualVersions);
    if (data) {
      yield put(fetchAccrualVersionSuccess({versions: data}));
    }
  } catch (error) {
    yield put(fetchAccrualVersionFailure());
  }
}
export function* getVersionDetails({payload}: any): any {
  try {
    const {simulationDesignId, action} = payload;
    const activeTenantId: number = getSessionStorage('tenantid');
    let getVersionDetail = `${ENDPOINTS.GET_SAVED_ACCRUL_DATA}?tenantid=${activeTenantId}&simulationdesignid=${simulationDesignId}`;
    if (action) {
      getVersionDetail += `&action=clone`;
    }
    const {data} = yield call(callApi, getVersionDetail);
    if (data) {
      const output = processOutput(data[0].output, data[0]?.rFormattedOutput || {});
      yield put(fetchVersionDetailsSuccess({versionDetails: data, output}));
    }
  } catch (error) {
    console.warn(error);
  }
}
export function* deleteAccrualVersion({payload}: any): any {
  try {
    const {simulationDesignId, versionName} = payload;
    const activeTenantId: number = getSessionStorage('tenantid');
    const getAccrualVersions = `${ENDPOINTS.DELETE_ACCRUAL_VERSION}?tenantid=${activeTenantId}&simulationdesignid=${simulationDesignId}`;
    const {data} = yield call(callApi, getAccrualVersions, {
      method: 'DELETE',
    });
    if (data) {
      //If data is there then pick the latest version and fetch it's details
      //to prepoulate the form else create new version
      yield put(toogleLoading(true));
      if (data.length) {
        const [latestVersion] = data;
        const {simulationDesignId: latestVersionSimulationId} = latestVersion;
        const getVersionDetail = `${ENDPOINTS.GET_SAVED_ACCRUL_DATA}?tenantid=${activeTenantId}&simulationdesignid=${latestVersionSimulationId}`;
        const {data: latestVersionData} = yield call(callApi, getVersionDetail);
        const output = processOutput(
          latestVersionData[0].output,
          latestVersionData[0]?.rFormattedOutput || {},
        );
        yield put(fetchVersionDetailsSuccess({versionDetails: latestVersionData, output}));
      } else {
        yield put(createNewVersion());
      }
      yield put(deleteAccrualVersionSuccess({versions: data}));
      yield put(
        openBanner({
          message: `Version ${versionName} has been deleted successfully`,
          bannerType: 'success',
          autoClose: true,
        }),
      );
      yield put(toogleLoading(false));
    }
  } catch (error) {
    yield put(deleteAccrualVersionFailure());
  }
}

export function* renameAccrualVersion({payload}: any): any {
  try {
    const {simulationDesignId, versionName, resultId, onExpand} = payload;
    const tenantId: number = getSessionStorage('tenantid');
    const userId: number = getSessionStorage('userid');
    const url = `${ENDPOINTS.SAVE_ACCRUAL_DATA}?tenantid=${tenantId}&resultid=${resultId}&userid=${userId}&autosave=true&status=Draft`;
    const {data} = yield call(callApi, url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({simulationDesignId, versionName, dropDownParams: []}),
    });
    if (data) {
      yield put(toogleLoading(true));
      if (data.length) {
        const output = hasCalculatedResults(data)
          ? processOutput(data[0].output, data[0]?.rFormattedOutput || {})
          : [];
        yield put(saveAcrrualSuccess({data, output}));
      }
      yield put(renameAccrualVersionSuccess());
      yield put(
        openBanner({
          message: `Version has been renamed successfully`,
          bannerType: 'success',
          autoClose: true,
        }),
      );
      yield put(toogleLoading(false));
      onExpand();
    }
  } catch (error:any) {
    yield put(renameAccrualVersionFailure());
    yield put(
      openBanner({
        message: `${error?.error}`,
        bannerType: 'warning',
        autoClose: true,
      }),
    );
  }
}
// We are sending oher accordion data along with Configure save. Below method structures formData in Formik format.
// This transformation is necessary as the format received in Saga for Configure groups is different from Formik.
const formatAccordianData = ({
  avgSites,
  medianRates,
  calculateStudyDuration,
  medianRatesTTE,
  calculateStudyDurationTTE,
}: any) => {
  const formnattedFormValues: any = {
    averageSiteDuration: {
      ...avgSites.averageSitesDefaults,
      reportingTimeUnitASD: avgSites.reportingTimeUnitASD,
      averageSiteDuration: [...avgSites.avgRates],
    },
    medianRecruitmentRates: {
      ...medianRates.medianRatesDefaults,
      reportingTimeUnitMRR: medianRates.reportingTimeUnitMRR,
      medianRecruitmentRates: [...medianRates.medianRates],
      pieceWiseAccruals: [...medianRates.pieceWiseAccruals],
    },
    calculateStudyDuration: {
      ...calculateStudyDuration,
    },
    medianRecruitmentRatesTTE: {
      ...medianRatesTTE.medianRatesTTEDefaults,
      reportingTimeUnitMRR: medianRatesTTE.reportingTimeUnitMRR,
      medianRecruitmentRatesTTE: [...medianRatesTTE.medianRatesTTE],
      pieceWiseAccrualsTTE: [...medianRatesTTE.pieceWiseAccrualsTTE],
      hazardRateTTE: [...medianRatesTTE.hazardRateTTE],
    },
    calculateStudyDurationTTE: {
      ...calculateStudyDurationTTE,
    },
  };
  return formnattedFormValues;
};
