import {useState, useEffect} from 'react';
import styles from './Tabs.module.scss';
import { useSelector } from 'react-redux';
import { getActiveUserRole } from '../../selectors/userDetailsSelector';
import DefaultTabs from './Default';
import TenantAdminTabs from './TenantAdmin';
import Banner from 'apollo-react/components/Banner';
import { RootState } from '../../../../app/store';
import { CONSTANTS } from '../../../../common/constants';

function ProjectTabs() {
  const [value, setValue] = useState(0);
  const [showBanner, setShowBanner] = useState<string>('');
    const {deleteProjectSuccess} = useSelector(
      (state: RootState) => state.createProjectSlice,
    );
  const handleChangeTab = (event: any, value: any) => {
    setValue(value);
  };
 useEffect(() => {
   if(deleteProjectSuccess){
      setShowBanner(CONSTANTS.INFORMATIONAL_MESSAGES.DELETE_PROJECT_SUCCESS(deleteProjectSuccess)); 
    }
  }, [deleteProjectSuccess]);

   const handleBannerClose = () => {
    setShowBanner('');
    };

  const activeUserRole = useSelector(getActiveUserRole);
  // TODO: To be revisited when we have more clarifty on other User roles.
  return (
    <>
    <div id='tabs' data-testid="tabs" className={styles.tabsContainer}>
      {activeUserRole === 'Tenant Admin' && <TenantAdminTabs value={value} tab='tenantAdmin' handleChangeTab={handleChangeTab} />}
      {activeUserRole !== 'Tenant Admin' && <DefaultTabs value={value} tab='all' handleChangeTab={handleChangeTab} />}
    </div>
    {showBanner && ( <Banner 
     variant="success"
     open={showBanner}
     onClose={() => handleBannerClose()}
     message={showBanner}
     style={{ top: '57px' }}
     />
      )}
     </>
  );
}

export default ProjectTabs;
