import { createSlice } from '@reduxjs/toolkit';

const createProjectSlice = createSlice({
  name: 'createProject',
  initialState: {
    sponsors: [],
    engagementTypes: [],
    projectCode: '',
    protocols: [],
    therapeuticAreas: [],
    otherTherapeuticAreas: [],
    objectives: [],
    phases: [],
    loading: false,
    newProjectId: '',
    duplicateName: false,
    updateProjectSuccess:'',
    deleteProjectSuccess:'',
    updateProjectError: '',
    deleteProjectError: '',
    isUpdating:false,
  },
  reducers: {
    getProjectFieldsData: (state, action) => {
      state.loading = true;
    },
    updateProjectFieldsData: (state, action) => {
      const { sponsors, engagementTypes, phases, therapeuticAreas, protocols, objectives, otherTherapeuticAreas } = action.payload;
      state.therapeuticAreas = therapeuticAreas;
      state.protocols = protocols;
      state.engagementTypes = engagementTypes;
      state.sponsors = sponsors;
      state.phases = phases;
      state.objectives = objectives;
      state.otherTherapeuticAreas = otherTherapeuticAreas;
      state.loading = false;
    },
    getSponsors: (state, action) => {
      state.loading = true;
    },
    updateSponsors: (state, action) => {
      state.sponsors = action.payload;
    },
    getProtocols: (state, action) => {
      state.loading = true;
    },
    updateProtocols: (state, action) => {
      state.protocols = action.payload;
    },
    getTherapeuticAreas: (state, action) => {
      state.loading = true;
    },
    updateTherapeuticAreas: (state, action) => {
      state.therapeuticAreas = action.payload;
    },
    getOtherTherapeuticAreas: (state, action) => {
      state.loading = true;
    },
    updateOtherTherapeuticAreas: (state, action) => {
      state.otherTherapeuticAreas = action.payload;
    },
    getObjectives: (state, action) => {
      state.loading = true;
    },
    updateObjectives: (state, action) => {
      state.objectives = action.payload;
    },
    getPhases: (state, action) => {
      state.loading = true;
    },
    updatePhases: (state, action) => {
      state.phases = action.payload;
    },
    createProject: (state, action) => {},
    createProjectSuccess: (state, action) => {
      state.newProjectId = action.payload;
    },
    resetProjectId: (state) => {
      state.newProjectId = '';
    },
    updateProject: (state, action) => { 
      state.isUpdating = true;

     },
    updateProjectError: (state, action) => {
      state.isUpdating = false;
      state.updateProjectError = action.payload;
    },
    deleteProjectError: (state, action) => {
      state.isUpdating = false;
      state.deleteProjectError = action.payload;
    },
    deleteProjectSuccess: (state, action) => {
      state.isUpdating = false;
      state.deleteProjectSuccess = action.payload;
    },
    updateProjectSuccess: (state, action) => {
      state.isUpdating = false;
      state.updateProjectSuccess = action.payload;
    },
    resetUpdateProject: (state) => {
      state.updateProjectError = '';
      state.updateProjectSuccess = '';
      state.deleteProjectError = '';
      state.deleteProjectSuccess = '';
      state.isUpdating = false;
    },
    checkDuplicateValues: (state, action) => {
      state.duplicateName = action.payload;
    },
  },
});

export const {
  getSponsors,
  updateSponsors,
  getProtocols,
  updateProtocols,
  getTherapeuticAreas,
  updateTherapeuticAreas,
  getOtherTherapeuticAreas,
  updateOtherTherapeuticAreas,
  getObjectives,
  updateObjectives,
  getPhases,
  updatePhases,
  createProject,
  createProjectSuccess,
  resetProjectId,
  updateProject,
  updateProjectSuccess,
  deleteProjectSuccess,
  updateProjectError,
  resetUpdateProject,
  checkDuplicateValues,
  getProjectFieldsData,
  updateProjectFieldsData,
  deleteProjectError
} = createProjectSlice.actions;

export default createProjectSlice.reducer;
